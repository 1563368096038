import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { useDispatch, useSelector } from "react-redux";
import {
   signInAsync,
   selectToken,
   selectUser,
} from "../features/authentication/authenticationSlice";
import {
   setUserToken,
   setUser,
} from "../features/localstorage/localStorageSlice";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import firebase from "firebase/app";
import { routeHistory } from "../routing/history";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

interface Values {
   email: string;
   password: string;
}

const initialValues: Values = {
   email: "",
   password: "",
};

const validationSchema = Yup.object().shape({
   email: Yup.string().email().required("Email is required"),
   password: Yup.string()
      .required("Password is required")
      .min(8, "Must have at least 8 characters")
      .max(15, "Must have 15 characters at most"),
});

const useStyles = makeStyles((theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: "#2196f3",
   },
   form: {
      width: "100%", 
      marginTop: theme.spacing(1),
      color: "#2196f3",
      borderColor: "#2196f3",
   },
   submit: {
      margin: theme.spacing(3, 0, 0),
      backgroundColor: "#2196f3",
   },
   create: {
      margin: theme.spacing(3, 0, 0),
      backgroundColor: "#3BBD1C",
   },
}));


export default function SignInPage() {
   const classes = useStyles();
   const dispatch = useDispatch();
   const token = useSelector(selectToken);
   const user = useSelector(selectUser);

   const [label, setLabel] = useState("Sign In");
   const [icon, setIcon] = useState(<VpnKeyIcon />);

   useEffect(() => {
      firebase.auth().onAuthStateChanged((user) => {
         if (user) {
            if (localStorage.getItem("user-last-path")) {
               routeHistory.push(localStorage.getItem("user-last-path"));
            } else {
               routeHistory.push("/");
            }
         } else {
            console.log("no user logged in");
         }
      });
   }, []);

   return (
      <Container component='main' maxWidth='xs'>
         <CssBaseline />
         <div className={classes.paper}>
            <Avatar className={classes.avatar}>
               <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
               Sign In
            </Typography>
            <Formik
               initialValues={initialValues}
               validationSchema={validationSchema}
               onSubmit={(values, {setSubmitting}) => {
                  setSubmitting(false)
                  setLabel("Loading...");
                  setIcon(<HourglassEmptyIcon />);
            
                  const payload = { email: values.email, password: values.password };
                  dispatch(signInAsync(payload));
                  if (token) {
                     dispatch(setUserToken(token));
                  }
                  if (user) {
                     dispatch(setUser(user));
                  }
               }}
            >
               {({ submitForm, isSubmitting, isValid, dirty }) => (
                  <Form>
                     <Field
                        component={TextField}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        type='email'
                     />
                     <Field
                        component={TextField}
                        type='password'
                        label='Password'
                        name='password'
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='password'
                        autoComplete='current-password'
                     />
                     <Button
                        onClick={submitForm}
                        fullWidth
                        variant='contained'
                        color='primary'
                        disabled={isSubmitting || !isValid || !dirty}
                        className={classes.submit}
                        startIcon={icon}
                     >
                        {label}
                     </Button>
                     <Grid container>
                        <Grid item>
                           <Link href='/forgot-password'>Forgot password?</Link>
                        </Grid>
                     </Grid>
                  </Form>
               )}
            </Formik>
         </div>
      </Container>
   );
}
