import { routeHistory } from './../../routing/history';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { UserDetail } from '../../types/UserDetail';
import firebase from 'firebase/app';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

interface AuthenticationState {
    token?: string;
    user?: UserDetail;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

interface AuthPayload {
    email: string;
    password: string;
}

const initialState: AuthenticationState = {
    loading: 'idle',
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        failedLogin: state => {
            state.token = undefined;
            state.user = undefined;
            state.loading = 'failed';
        },
        successfulLogin: (state, action: PayloadAction<UserDetail>) => {
            state.token = action.payload.token;
            state.user = action.payload;
            state.loading = 'succeeded';
        },
        signOut: state => {
            state.user = undefined;
            state.token = undefined;
            state.loading = 'succeeded';
        }
    },
});

export const { failedLogin, successfulLogin, signOut } = authenticationSlice.actions;

export const signInAsync = (authPayload: AuthPayload): AppThunk => dispatch => {
    firebase.auth().signInWithEmailAndPassword(authPayload.email, authPayload.password)
        .then(function (result) {
            if (result != null && result.user != null) {
                result.user.getIdToken().then(function (idToken) {
                    const accessToken = idToken;
                    if (result != null && result.user != null) {
                        const user = {
                            email: result.user.email,
                            token: accessToken
                        } as UserDetail;
                        setTimeout(() => {
                            dispatch(successfulLogin(user));
                        }, 300);
                    }
                });
            };
        }).catch(function (error) {
            routeHistory.push('/templates')
            toast.error(error.message);
        });

};


export const signOutAsync = (): AppThunk => dispatch => {
    firebase.auth().signOut().then(function () {
        console.log('Signed out firebase successful');
        setTimeout(() => {
            dispatch(signOut());
        }, 300);
    }).catch(function (error) {
        console.log(error);
    });
};


export const selectToken = (state: RootState) => state.authentication.token;
export const selectUser = (state: RootState) => state.authentication.user;
export const selectLoadingAuth = (state: RootState) => state.authentication.loading;

export default authenticationSlice.reducer;