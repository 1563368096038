import React from "react";
import { ListItemIcon, Divider, List, ListItemText } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import HomeIcon from '@material-ui/icons/Home';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Link } from "react-router-dom";
import HomePage from "../../pages/HomePage";

const data = {
  menus: [
    { text: "Home", icon: <HomeIcon />, link: "/", component: { HomePage } },
    { text: "Templates", icon: <CheckCircleOutlineIcon />, link: "/templates", component: { HomePage } },
    { text: "Requests", icon: <CheckCircleOutlineIcon />, link: "/requests", component: { HomePage } },
  ]
}


export default function AppDrawerMenu() {
  return (
    <div>
      <Divider />
      <List>
        {data.menus.map((menu, index) => (
          <ListItem button key={menu.text} to={menu.link} component={Link}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
}
