import React, { useState } from "react";
import {
   createStyles,
   lighten,
   makeStyles,
   Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Box, Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Search } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import {
   selectTableRows,
   setRowFilter,
   clearRowFilter,
   selectFiltered,
   initialState
} from "./rowSlice";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import "./Table.css";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

interface Data {
   calories: number;
   carbs: number;
   fat: number;
   name: string;
   copy: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
   order: Order,
   orderBy: Key
): (
   a: { [key in Key]: number | string },
   b: { [key in Key]: number | string }
) => number {
   return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
   disablePadding: boolean;
   id: keyof Data;
   label: string;
   numeric: boolean;
   disableSorting: boolean;
}

const headCells: HeadCell[] = [
   {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Dessert (100g serving)",
      disableSorting: false,
   },
   {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Calories",
      disableSorting: false,
   },
   {
      id: "fat",
      numeric: true,
      disablePadding: false,
      label: "Fat (g)",
      disableSorting: false,
   },
   {
      id: "carbs",
      numeric: true,
      disablePadding: false,
      label: "Carbs (g)",
      disableSorting: false,
   },
   {
      id: "copy",
      numeric: false,
      disablePadding: false,
      label: "", // Copy
      disableSorting: true,
   },
];

interface EnhancedTableProps {
   classes: ReturnType<typeof useStyles>;
   onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
   ) => void;
   order: Order;
   orderBy: string;
   rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
   const { classes, order, orderBy, onRequestSort } = props;
   const createSortHandler = (property: keyof Data) => (
      event: React.MouseEvent<unknown>
   ) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead>
         <TableRow>
            {headCells.map((headCell) => (
               <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === headCell.id ? order : false}
               >
                  {headCell.disableSorting ? (
                     headCell.label
                  ) : (
                     <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                     >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                           <span className={classes.visuallyHidden}>
                              {order === "desc"
                                 ? "sorted descending"
                                 : "sorted ascending"}
                           </span>
                        ) : null}
                     </TableSortLabel>
                  )}
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(1),
      },
      highlight:
         theme.palette.type === "light"
            ? {
                 color: theme.palette.secondary.main,
                 backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                 color: theme.palette.text.primary,
                 backgroundColor: theme.palette.secondary.dark,
              },
      title: {
         flex: "1 1 100%",
         fontFamily: "'Montserrat', sans-serif",
         fontWeight: 600,
         color: "#303642",
         lineHeight: "31.5px",
         fontSize: "24px",
         textAlign: "center",
         fontStyle: "normal",
         width: "129px",
         height: "32px",
         // position: "absolute",
         // left: 0,
         // right: 0,
         // width: "100%",
      },
      new: {
         textDecoration: "none",
         backgroundColor: "#F66000",
         color: "#FFFFFF",
         textTransform: "none",
         fontFamily: "Montserrat",
         fontStyle: "normal",
         fontWeight: 700,
         fontSize: "14px",
         lineHeight: "22px",
         display: "flex",
         alignItems: "center",
         textAlign: "center",
         width: "147px",
         height: "32px",
      },
   })
);

const EnhancedTableToolbar = () => {
   const classes = useToolbarStyles();
   const [filterOpen, setFilterOpen] = useState(false);
   const dispatch = useDispatch();
   const filtered = useSelector(selectFiltered);

   return (
      <Toolbar style={{ backgroundColor: "#DEE1E5" }}>
         <Typography
            className={classes.title}
            variant='h6'
            id='tableTitle'
            component='div'
         >
            <span id='titleSpan'>Nutrition</span>
         </Typography>
         <Button className={classes.new}>New</Button>
         <Toolbar>
            {filterOpen ? (
               <div>
                  <Formik
                     initialValues={{
                        filter: "",
                     }}
                     onSubmit={(values, { resetForm }) => {
                        const initialRows = initialState.rows
                        const filteredRows = initialRows.filter((x: any) =>
                           x.name
                              .toLowerCase()
                              .includes(values.filter.toLowerCase())
                        );
                        if (filteredRows.length === 0) {
                           alert("There is no match for your search.");
                        } else {
                           dispatch(setRowFilter(filteredRows));
                        }
                        resetForm();
                     }}
                  >
                     {({ submitForm, dirty }) => (
                        <Form>
                           <Box margin={1} display='flex'>
                              <Field
                                 component={TextField}
                                 name='filter'
                                 type='text'
                                 label='Filter'
                              />
                              <IconButton
                                 color={dirty ? "primary" : "secondary"}
                                 aria-label={dirty ? "Search" : "Clear"}
                                 component='span'
                                 disabled={!filtered && !dirty}
                                 onClick={
                                    dirty
                                       ? submitForm
                                       : () => dispatch(clearRowFilter())
                                 }
                              >
                                 {dirty ? <Search /> : <ClearIcon />}
                              </IconButton>
                           </Box>
                        </Form>
                     )}
                  </Formik>
               </div>
            ) : null}
         </Toolbar>
         <Tooltip title='Filter list'>
            <IconButton
               onClick={() => setFilterOpen(!filterOpen)}
               aria-label='filter list'
            >
               <FilterListIcon />
            </IconButton>
         </Tooltip>
      </Toolbar>
   );
};

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         width: "100%",
      },
      paper: {
         width: "100%",
         marginBottom: theme.spacing(2),
      },
      table: {
         minWidth: 750,
      },
      visuallyHidden: {
         border: 0,
         clip: "rect(0 0 0 0)",
         height: 1,
         margin: -1,
         overflow: "hidden",
         padding: 0,
         position: "absolute",
         top: 20,
         width: 1,
      },
      copy: {
         color: "#94A2AB",
      },
   })
);

export default function BasicTable() {
   const classes = useStyles();
   const [order, setOrder] = useState<Order>("asc");
   const [orderBy, setOrderBy] = useState<keyof Data>("calories");
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const rows = useSelector(selectTableRows);

   const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data
   ) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   return (
      <div className={classes.root}>
         <Paper className={classes.paper}>
            <EnhancedTableToolbar />
            <TableContainer>
               <Table
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size='medium'
                  aria-label='enhanced table'
               >
                  <EnhancedTableHead
                     classes={classes}
                     order={order}
                     orderBy={orderBy}
                     onRequestSort={handleRequestSort}
                     rowCount={rows.length}
                  />
                  <TableBody>
                     {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                           page * rowsPerPage,
                           page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                           return (
                              <TableRow
                                 hover
                                 role='checkbox'
                                 tabIndex={-1}
                                 key={row.name}
                              >
                                 <TableCell
                                    component='th'
                                    id='enhanced-table-checkbox'
                                    scope='row'
                                    padding='default'
                                 >
                                    {row.name}
                                 </TableCell>
                                 <TableCell align='left'>
                                    {row.calories}
                                 </TableCell>
                                 <TableCell align='left'>{row.fat}</TableCell>
                                 <TableCell align='left'>{row.carbs}</TableCell>
                                 <TableCell id='copyItem' align='right'>
                                    <IconButton
                                       className={classes.copy}
                                       aria-label='Copy'
                                       component='span'
                                       onClick={() =>
                                          console.log(
                                             "row copied ==>>",
                                             page * rowsPerPage + index
                                          )
                                       }
                                    >
                                       <FileCopyOutlinedIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               component='div'
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onChangePage={handleChangePage}
               onChangeRowsPerPage={handleChangeRowsPerPage}
               style={{ backgroundColor: "#DEE1E5" }}
            />
         </Paper>
      </div>
   );
}
