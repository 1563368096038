import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import cuid from "cuid";
import {
   Box,
   FormControl,
   FormControlLabel,
   IconButton,
   MenuItem,
   Radio,
   RadioGroup,
   TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Field } from "formik";

const loremIpsum =
   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

const useStyles = makeStyles({
   root: {
      minWidth: 275,
   },
   bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
   },
   title: {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      color: "#303642",
   },
   pos: {
      marginBottom: 12,
   },
});

export default function MUICard({ id, cards, setCards }: any) {
   const classes = useStyles();
   const [templateType, setTemplateType] = useState("text");

   function onRadioChange(e: any) {
      setTemplateType(e.target.value);
      console.log(e.target.name, e.target.value);
   }

   function handleAddNewCard() {
      const newCard = { id: cuid() };
      setCards([...cards, newCard]);
   }

   function handleDeleteCard(id: number) {
      setCards(cards.filter((e) => e.id !== id));
   }

   return (
      <Card className={classes.root}>
         <CardContent>
            <Box margin={1} display='flex'>
               <Box display='block'>
                  <Typography className={classes.title}>Label</Typography>
                  <Field
                     as={TextField}
                     variant='outlined'
                     name={`label-${id}`}
                     value={undefined}
                     type='string'
                     placeholder='Label'
                  />
               </Box>
               <FormControl component='fieldset'>
                  <RadioGroup
                     aria-label='quiz'
                     name='textOrPhoto'
                     value={templateType}
                     onChange={onRadioChange}
                  >
                     <Box display='block'>
                        <Typography className={classes.title}>
                           Content Type
                        </Typography>
                        <Box margin={1}>
                           <FormControlLabel
                              value='text'
                              control={<Radio />}
                              label='Text'
                           />
                           <FormControlLabel
                              value='photo'
                              control={<Radio />}
                              label='Photo'
                           />
                        </Box>
                     </Box>
                  </RadioGroup>
               </FormControl>
            </Box>

            {templateType === "text" && (
               <>
                  {" "}
                  <Typography className={classes.title}>
                     Text box size & shape
                  </Typography>
                  <Field
                     as='textarea'
                     variant='outlined'
                     id={id}
                     name={`text-${id}`}
                     type='string'
                     label='Description'
                  />
               </>
            )}
            {templateType === "photo" && (
               <>
                  <Box display='flex'>
                     <Box display='block' className='photoBox'>
                        <Typography className={classes.title}>
                           Min Width
                        </Typography>{" "}
                        <Field
                           as={TextField}
                           variant='outlined'
                           name={`minWidth-${id}`}
                           type='number'
                           value={undefined}
                        />
                     </Box>
                     <Box display='block' className='photoBox'>
                        <Typography className={classes.title}>
                           Min Height
                        </Typography>
                        <Field
                           as={TextField}
                           variant='outlined'
                           name={`minHeight-${id}`}
                           value={undefined}
                           type='number'
                        />
                     </Box>
                     <Box display='block' className='photoBox'>
                        <Typography className={classes.title}>
                           Caption
                        </Typography>
                        <FormControl variant='outlined'>
                           <Field
                              as={TextField}
                              variant='outlined'
                              select
                              name={`caption-${id}`}
                           >
                              <MenuItem value='no'>No</MenuItem>
                              <MenuItem value='yes-optional'>
                                 Yes - optional
                              </MenuItem>
                              <MenuItem value='yes-required'>
                                 Yes - required
                              </MenuItem>
                           </Field>
                        </FormControl>
                     </Box>
                  </Box>
                  <Typography className={classes.title}>
                     Caption box size & shape
                  </Typography>
                  <Field
                     as='textarea'
                     id={id}
                     name={`text-${id}`}
                     placeholder={loremIpsum}
                  />
               </>
            )}
         </CardContent>
         <CardActions>
            <IconButton onClick={handleAddNewCard} aria-label='add'>
               <AddIcon />
            </IconButton>
            <IconButton
               onClick={() => handleDeleteCard(id)}
               aria-label='delete'
            >
               <DeleteIcon />
            </IconButton>
         </CardActions>
      </Card>
   );
}
