import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const styles = {
   main: {
      paddingTop: 80,
      paddingLeft: "1%",
      paddingRight: "1%",
   },
   logoWritten: {
      paddingLeft: "11px",
   },
};

interface PageBaseProps {
   children: React.ReactChild;
}

const PageBase: React.FC<PageBaseProps> = ({ children }) => {

   const location = window.location.pathname;
   const templates = location === '/templates' || location === '/templates'
   const requests = location === '/requests' || location === '/requests'

   return (
      <div style={styles.main}>
         <Box margin={1} display='flex' style={{ alignItems: "center" }}>
            <img alt='tumlaLogo' src='tumla_logo.png' />
            <img alt='tumla' src='TUMLA.png' style={styles.logoWritten} />
            <Box margin={0} display='flex' style={{ paddingLeft: "346px" }}>
               <Link
                  to='/templates'
                  style={{
                     fontFamily: "Montserrat",
                     fontStyle: "normal",
                     fontWeight: "bold",
                     color: templates ? "#F66000" : "#94A2AB",
                     textDecoration: "underline",
                  }}
               >
                  Templates
               </Link>
               <Link
                  to='/requests'
                  style={{
                     fontFamily: "Montserrat",
                     fontStyle: "normal",
                     fontWeight: "bold",
                     paddingLeft: "50px",
                     color: requests ? "#F66000" : "#94A2AB",
                  }}
               >
                  Requests
               </Link>
            </Box>
         </Box>

         {children}
      </div>
   );
};

export default PageBase;
