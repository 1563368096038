import React from "react";
import { Typography } from "@material-ui/core";
import PageBase from "../components/PageBase";

export default function HomePage() {
   return (
      <div>
         <PageBase>
            <Typography>Home Page</Typography>
         </PageBase>
      </div>
   );
}
