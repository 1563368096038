import { createSlice } from "@reduxjs/toolkit";

interface Data {
   calories: number;
   carbs: number;
   fat: number;
   name: string;
   protein: number;
}

export const initialState = {
   rows: [
      { name: "Cupcake", calories: 305, carbs: 3.7, fat: 67, protein: 4.3 },
      { name: "Donut", calories: 452, carbs: 25.0, fat: 51, protein: 4.9 },
      { name: "Eclair", calories: 262, carbs: 16.0, fat: 24, protein: 6.0 },
      {
         name: "Frozen yoghurt",
         calories: 159,
         carbs: 6.0,
         fat: 24,
         protein: 4.0,
      },
      {
         name: "Gingerbread",
         calories: 356,
         carbs: 16.0,
         fat: 49,
         protein: 3.9,
      },
      { name: "Honeycomb", calories: 408, carbs: 3.2, fat: 87, protein: 6.5 },
      {
         name: "Ice cream sandwich",
         calories: 237,
         carbs: 9.0,
         fat: 37,
         protein: 4.3,
      },
      { name: "Jelly Bean", calories: 375, carbs: 0.0, fat: 94, protein: 0.0 },
      { name: "KitKat", calories: 518, carbs: 26.0, fat: 65, protein: 7.0 },
      { name: "Lollipop", calories: 392, carbs: 0.2, fat: 98, protein: 0.0 },
      { name: "Marshmallow", calories: 318, carbs: 0, fat: 81, protein: 2.0 },
      { name: "Nougat", calories: 360, carbs: 19.0, fat: 9, protein: 37.0 },
      { name: "Oreo", calories: 437, carbs: 18.0, fat: 63, protein: 4.0 },
   ],
   filtered: false,
};

export const rowSlice = createSlice({
   name: "row",
   initialState,
   reducers: {
      setRowFilter: (state, action) => {
         state.rows = action.payload;
         state.filtered = true;
      },
      clearRowFilter: (state) => {
         state.rows = initialState.rows;
         state.filtered = false;
      },
   },
});

export const { setRowFilter, clearRowFilter } = rowSlice.actions;

export const selectTableRows = (state: any) => state.rowReducer.rows;
export const selectFiltered = (state: any) => state.rowReducer.filtered;

export default rowSlice.reducer;
