import React, { useState, useCallback, useEffect } from "react";
import { Card } from "./Card";
import update from "immutability-helper";
import cuid from 'cuid';
import { useDispatch } from "react-redux";
import { setCardsCopy } from "../templatesSlice";

export default function Container() {
   const dispatch = useDispatch()
   const [cards, setCards] = useState([
      {
         id: cuid(),
      },
      {
         id: cuid(),
      },
   ]);

   useEffect(() => {
      dispatch(setCardsCopy(cards))
   },[dispatch, cards])
   
   const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
         const dragCard = cards[dragIndex];
         setCards(
            update(cards, {
               $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, dragCard],
               ],
            })
         );
      },
      [cards]
   );
   const renderCard = (card:any, index:any) => {
      // console.log(cards)
      return (
         <Card
            key={card.id}
            index={index}
            id={card.id}
            moveCard={moveCard}
            cards={cards}
            setCards={setCards}
         />
      );
   };
   return (
      <>
         <div style={{width: '100%'}}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
   );
};
