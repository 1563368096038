import React from "react";
import PageBase from "../components/PageBase";
import TemplatesTable from '../features/templates/TemplatesTable';

export default function Templates() {
   return (
      <PageBase>
         <TemplatesTable/>
      </PageBase>
   );
}
