import React from 'react'
import PageBase from '../components/PageBase'
import BasicTable from '../table/BasicTable'

export default function RequestsPage(){
   return (
      <PageBase>
         <BasicTable/>
      </PageBase>
      )
}