import React, { useEffect, useState } from "react";
import {
   Dialog,
   DialogTitle,
   DialogContent,
   Button,
   Box,
   Typography,
   makeStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import firebase from "firebase/app";
import "firebase/functions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "./dragNdrop/Container";
import "./MuiDialog.css";
import { useSelector } from "react-redux";
import { selectCardsCopy } from "./templatesSlice";
import * as Yup from "yup";

toast.configure();

const useStyles = makeStyles({
   title: {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      color: "#303642",
   },
});


export default function MuiDialog({ open, setDialogOpen }) {
   const [disabled, setDisabled] = useState(false);
   const classes = useStyles();
   const cardsCopy = useSelector(selectCardsCopy);
   const [initialValues, setInitialValues] = useState({
      templateTitle: "",
      description: "",
   });

   let ids = [];
   const idsObj = Object.values(cardsCopy);
   idsObj.forEach((obj) => ids.push(Object.values(obj)[0]));


   useEffect(() => {
      ids.forEach((id) => {
         initialValues[`caption-${id}`] = "";
         setInitialValues(initialValues);
      });
   }, [ids, initialValues]);

   const validationSchema = Yup.object().shape({
      templateTitle: Yup.string().required("Template title is required"),
      description: Yup.string().required("Description is required")
   })

   return (
      <Dialog id='justDialog' open={open} onClose={() => setDialogOpen(false)}>
         <DialogTitle>
            <Typography>New Template</Typography>
         </DialogTitle>
         <DialogContent>
            <div id='alert-dialog-description'>
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                     setDisabled(true);
                     let newDoc = {};
                     let ids = [];
                     let request = {};
                     let contents = [];
                     for (const [key, value] of Object.entries(values)) {
                        let ol;
                        let id;
                        let type;
                        let allTypes = [];
                        if (key.includes("-")) {
                           ol = key.split("-");
                           id = ol[1];
                           type = ol[0];
                           if (request[id] === undefined) request[id] = {};
                           request[id][type] = value;
                           allTypes.push(type);
                        }

                        if (!key.includes("label")) continue;
                        ids.push(id);
                     }

                     for (const id of ids) {
                        let textBoxField: any = document.querySelector(
                           `#${id}`
                        );

                        if (textBoxField) {
                           let boxWidth = getComputedStyle(textBoxField).width;
                           let boxHeight = getComputedStyle(textBoxField)
                              .height;

                           request[id]["boxHeight"] = boxHeight;
                           request[id]["boxWidth"] = boxWidth;
                        }
                     }

                     for (const [key, value] of Object.entries(request)) {
                        request[key]["contentType"] = request[key]["caption"]
                           ? "photo"
                           : "text";
                        if (value.hasOwnProperty('label')){
                           contents.push(value);
                        }
                     }

                     newDoc["contents"] = contents;
                     newDoc["elements"] = contents.length;
                     newDoc["templateTitle"] = values.templateTitle;
                     newDoc["description"] = values.description;

                     let db = firebase.firestore();
                     let templatesRef = db
                        .collection("yearbook_templates")
                        .doc();
                     templatesRef
                        .set(newDoc)
                        .then(function () {
                           setDialogOpen(false)
                           toast.success("Data successfully sent!");
                        })
                        .catch(function (error) {
                           setDialogOpen(false)
                           toast.error(error.message);
                        });
                  }}
               >
                  {({ submitForm, isValid, dirty, values }) => (
                     <Form>
                        <Box display='grid' id='titleDescription'>
                           <Typography
                              className={classes.title}
                              color='primary'
                           >
                              Template Title
                           </Typography>
                           <Field
                              component={TextField}
                              name='templateTitle'
                              variant='outlined'
                              type='string'
                              placeholder='Template Title'
                           />
                           <Typography
                              className={classes.title}
                              color='primary'
                           >
                              Description
                           </Typography>
                           <Field
                              component={TextField}
                              variant='outlined'
                              name='description'
                              type='string'
                              placeholder='Description'
                           />
                        </Box>
                        <Box>
                           <Container />
                        </Box>
                        <Box>
                           <Button
                              variant='outlined'
                              color='primary'
                              disabled={!dirty || !isValid || disabled}
                              onClick={submitForm}
                           >
                              Submit
                           </Button>
                        </Box>
                     </Form>
                  )}
               </Formik>
            </div>
         </DialogContent>
      </Dialog>
   );
}
